import React, { useEffect, useRef } from 'react';
import { USER_ROLES, COMPANY_TYPES, APP_ENV, PTA_SUBDOMAIN, STORAGEKEY_DEV_SUBDOMAIN } from 'config/constants';
import isNumeric from 'validator/lib/isNumeric';
import ClockIcon from 'assets/images/general/clock-green.svg';
import OrangeClockIcon from 'assets/images/general/clock-orange.svg';
import CautionIcon from 'assets/images/general/caution-sign.svg';
import NumberFormat from 'react-number-format';
import ClockWhiteIcon from 'assets/images/general/clock-white.svg';
import { notifType, notify } from 'utils/notifSender';
import { isAfter, parse } from 'date-fns';

// const { useEffect, useRef } = require('react');

export function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}

export function useOnClickOutside(ref, handler, except) {
    useEffect(
        () => {
            const listener = event => {
                // Do nothing if clicking ref's element or descendent elements
                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }

                if (except && except.current.contains(event.target)) {
                    return;
                }

                handler(event);
            };

            document.addEventListener('mousedown', listener);
            document.addEventListener('touchstart', listener);

            return () => {
                document.removeEventListener('mousedown', listener);
                document.removeEventListener('touchstart', listener);
            };
        },
        // Add ref and handler to effect dependencies
        // It's worth noting that because passed in handler is a new ...
        // ... function on every render that will cause this effect ...
        // ... callback/cleanup to run every render. It's not a big deal ...
        // ... but to optimize you can wrap handler in useCallback before ...
        // ... passing it into this hook.
        [ref, handler, except]
    );
}

export function priceValidator(value, attributes, isDirty) {
    return (
        value === '' && !isDirty && attributes.default_price && Number(attributes.default_price) !== 0
    ) || (isNumeric(value) && parseFloat(value) !== 0);
}

export function volumeValidator(value, attributes, isDirty) {
    return (
        value === '' && !isDirty && attributes.default_volume && attributes.default_volume > 0
    ) || (isNumeric(value) && parseFloat(value) > 0) || (!attributes.is_firm?.value && value !== '0');
}

export function secondaryClientValidator(value, attributes, isDirty) {
    return value && value !== attributes.company_id.value;
}

export function timeDifference(current, previous) {
    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = current - previous;

    if (elapsed < msPerMinute) {
        return Math.round(elapsed / 1000) + ' sec ago';
    } else if (elapsed < msPerHour) {
        return Math.round(elapsed / msPerMinute) + ' min ago';
    } else if (elapsed < msPerDay) {
        return Math.round(elapsed / msPerHour) + ' hr ago';
    } else if (elapsed < msPerMonth) {
        const days = Math.round(elapsed / msPerDay);

        return 'approx. ' + days + (days > 1 ? ' days ago' : ' day ago');
    } else if (elapsed < msPerYear) {
        const months = Math.round(elapsed / msPerMonth);

        return 'approx. ' + months + (months > 1 ? ' months ago' : ' month ago');
    } else {
        const years = Math.round(elapsed / msPerYear);

        return 'approx. ' + years + (years > 1 ? ' years ago' : ' year ago');
    }
}

export function convertIdToRole(roleId) {
    return roleId === USER_ROLES.TRADER ? 'Trader'
        : roleId === USER_ROLES.BROKERAGE ? 'Broker'
            : roleId === USER_ROLES.MO ? 'MO'
                : roleId === USER_ROLES.BROKERCOMPANY ? 'Brokerage Company'
                    : roleId === USER_ROLES.DATA_USER ? 'Data User Company'
                        : 'Admin';
}

export function convertCompanyToType(typeId) {
    return typeId === COMPANY_TYPES.BROKERAGE ? 'Brokerage'
        : typeId === COMPANY_TYPES.TRADING_COMPANY ? 'Trading' : 'Data User';
}

export function calculateLimit(utilized, limit, type, prefix, tooltip) {
    if (utilized === 0) {
        // return clock with 0 value
        if (type && type === 'total') {
            return <div className='input-box__right-head'>
                <img className='input-box__head-icon' src={!tooltip ? ClockIcon : ClockWhiteIcon} alt={'Current positive MTM'} />{utilized}
            </div>;
        }

        return <div className='usage-value'>
            <img src={!tooltip ? ClockIcon : ClockWhiteIcon} alt='current usage' />{utilized}
        </div>;
    }

    if (utilized && limit) {
        // calculate (utilized / limit) * 100 with abs
        const percentage = Math.abs(Math.floor((utilized / limit) * 100));

        if (percentage >= 100) {
        // if equal to 100% - Red  (Change icon to alert icon )
            if (type && type === 'total') {
                return <div className='input-box__right-head right-head-alert'>
                    <img className='input-box__head-icon' src={CautionIcon} alt={'current usage'} />
                    <NumberFormat value={utilized} displayType={'text'} thousandSeparator={true}
                        prefix={prefix} />
                </div>;
            }

            return <div className='usage-value usage-value-alert'>
                <img src={CautionIcon} alt='current usage' />
                { !tooltip
                    ? <NumberFormat value={utilized} displayType={'text'} thousandSeparator={true}
                        prefix={prefix} />
                    : <NumberFormat value={utilized} displayType={'text'} thousandSeparator={true}
                        suffix={prefix} />
                }
            </div>;
        } else
        if (percentage >= 85 && percentage <= 99) {
            // if equal to 85% (Less then 99%) - Orange
            if (type && type === 'total') {
                return <div className='input-box__right-head right-head-warning'>
                    <img className='input-box__head-icon' src={OrangeClockIcon} alt={'current usage'} />
                    <NumberFormat value={utilized} displayType={'text'} thousandSeparator={true}
                        prefix={prefix} />
                </div>;
            }

            return <div className='usage-value usage-value-warning'>
                <img src={OrangeClockIcon} alt='current usage' />
                { !tooltip
                    ? <NumberFormat value={utilized} displayType={'text'} thousandSeparator={true}
                        prefix={prefix} />
                    : <NumberFormat value={utilized} displayType={'text'} thousandSeparator={true}
                        prefix={prefix} />
                }
            </div>;
        } else {
            // else green
            if (type && type === 'total') {
                return <div className='input-box__right-head'>
                    <img className='input-box__head-icon' src={!tooltip ? ClockIcon : ClockWhiteIcon} alt={'current usage'} />
                    <NumberFormat value={utilized} displayType={'text'} thousandSeparator={true}
                        prefix={prefix} />
                </div>;
            }

            return <div className='usage-value'>
                <img src={!tooltip ? ClockIcon : ClockWhiteIcon} alt='current usage' />
                { !tooltip
                    ? <NumberFormat value={utilized} displayType={'text'} thousandSeparator={true}
                        prefix={prefix} />
                    : <NumberFormat value={utilized} displayType={'text'} thousandSeparator={true}
                        suffix={prefix} />
                }
            </div>;
        }
    }

    // return true;
}

export const handleApiErrors = (errors, returnFields) => {
    const fields = Object.keys(errors);

    for (const field of fields) {
        errors[field].map(msg => notify(msg, notifType.DANGER));
    }

    if (returnFields) {
        return fields;
    }
};

export const checkIfLocalTimeIsBeyond = (comparisonDateTime) => {
    // Define the format of the input date-time string
    const format = 'yyyy-MM-dd HH:mm:ss';

    // Parse the date-time string into a Date object
    const parsedDateTime = parse(comparisonDateTime, format, new Date());

    // Get the current local date and time
    const localDateTime = new Date();

    // Check if the local date and time is beyond the specified date-time
    return isAfter(localDateTime, parsedDateTime);
};

export const convertDateTimeTThisTimeZone = (date, tzString) => {
    return new Date((typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', { timeZone: tzString }));
};

export const isValidPositiveNumber = (input) => {
    const regex = /^\d*\.?\d+$/;

    return regex.test(input);
};

export const onScrollBottomEvent = (callBack = () => false) => (ee) => {
    const bottom =
        Math.floor(ee.target.scrollHeight - ee.target.scrollTop) ===
        ee.target.clientHeight;

    if (bottom) {
        callBack();
    }
};

export const onScrollRigthEvent = (callBack = () => false) => (ee) => {
    const rightMost =
        Math.floor(ee.target.scrollWidth - ee.target.scrollLeft) ===
        ee.target.clientWidth;

    if (rightMost) {
        callBack();
    }
};

export const onScrollFetchMoreInfiniteQuery =
    (api) => () => {
        if (api.hasNextPage && !api.isFetching) {
            api.fetchNextPage();
        }
    };

export const getSubdomain = () => {
    if (APP_ENV === 'local') {
        let domain = localStorage.getItem(STORAGEKEY_DEV_SUBDOMAIN);

        window.location.search.split('?')[1]?.split('&').forEach((paramPair) => {
            const keyValPairs = paramPair.split('=');

            if (keyValPairs[0] === 'subdomain') {
                domain = keyValPairs[1];
            }
        });

        if (domain) {
            localStorage.setItem(STORAGEKEY_DEV_SUBDOMAIN, domain);
        }

        return domain;
    }

    const hostname = window.location.hostname;

    const parts = hostname.split('.').filter(dd => dd !== 'www');

    if (parts.length > 3 && APP_ENV === 'staging' && parts[parts.length - 4] !== 'dev') {
        return parts[parts.length - 4];
    } else if (parts.length > 2 && APP_ENV === 'production' && parts[parts.length - 3] !== 'platform') {
        return parts[parts.length - 3];
    }

    return undefined;
};

export const isInMainPlatform = () => {
    return PTA_SUBDOMAIN === getSubdomain();
};
